input {
    color: white;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

#nav {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0, 10, 20, 0.9);
    backdrop-filter: blur(6px);
    font-weight: 500;
    display: flex;
    -moz-box-align: stretch;
    align-items: stretch;
    flex-wrap: nowrap;
    padding-top: 5px;
    padding-bottom: 5px;
}

.navbar {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0, 10, 20, 0.9);
    backdrop-filter: blur(6px);
    font-weight: 500;
    display: flex;
    -moz-box-align: stretch;
    align-items: stretch;
    flex-wrap: nowrap;
    padding-top: 5px;
    padding-bottom: 5px;
    flex-direction: row;
}

.navbar-expand .navbar-collapse {
    flex-basis: 100% !important;
}

.nav-el {
    float: left;
    font-size: 20pt;
    color: white;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x) !important;
    padding-right: var(--bs-navbar-nav-link-padding-x) !important;
    padding-left: var(--bs-navbar-nav-link-padding-x) !important;
}

.nav-el:disabled {
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
}

.nav-el:hover:disabled {
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
}

.nav-el:hover:not(:disabled) {
    color: #2a52be !important;
}

.nav-el a {
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    text-decoration: none;
}

.nav-el a:hover {
    text-decoration: none;
    color: #2a52be;
}

.nav-el a:visited {
    color: white;
    text-decoration: none;
}

.nav-el a:visited:hover {
    color: #2a52be;
    text-decoration: none;
}

#search-bar {
    display: flex;
    align-items: center;
    -moz-box-align: center;
    justify-content: center;
    -moz-box-pack: center;
    width: 100%;
}

.navbar-toggler {
    border: none !important;
    box-shadow: none !important;
}

.navbar-toggler:active {
    border: none !important;
    box-shadow: none !important;
}

.navbar-toggler:focus {
    border: none !important;
    box-shadow: none !important;
}

#search-bar input {
    width: var(--search-bar-width);
}

#Home {
    text-align: center;
}

#side-bar {
    position: fixed;
    width: 10%;
    height: 100%;
    text-align: center;
    z-index: 999;
    background-color: #000a14;
}

.side-bar-item {
    width: 90%;
    padding-left: 10px;
    padding-right: 10px;
}

.side-bar-item input {
    width: 100%;
}

.side-bar-item textarea {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
}

.standard-button {
    border: 1px solid white;
    border-radius: 5px;
    background-color: transparent;
    color: white;
    font-size: 14px;
    padding: 5px;
}

.standard-button-large {
    border: 1px solid white;
    border-radius: 5px;
    background-color: transparent;
    color: white;
    padding: 5px;
    margin: 5px;
    font-size: 16px;
}

.standard-button:hover:enabled {
    background-color: white;
    color: black;
    cursor: pointer;
}

.standard-button:disabled {
    color: darkgray;
    border: 1px solid darkgray;
    cursor: not-allowed;
}

.standard-button-large:hover:enabled {
    background-color: white;
    color: black;
    cursor: pointer;
}

.standard-button-large:disabled {
    color: darkgray;
    border: 1px solid darkgray;
    cursor: not-allowed;
}

.underscore-button {
    text-decoration: underline;
    background-color: transparent;
    color: white;
    border: none;
    font-size: medium;
}

.underscore-button:hover:enabled {
    cursor: pointer;
}

.underscore-button:disabled {
    color: darkgray;
    cursor: not-allowed;
}

.standard-form {
    display: flex;
    border: 2px solid white;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #161b22;
    -moz-box-shadow: 0 0 3px #ccc;
    -webkit-box-shadow: 0 0 3px #ccc;
    box-shadow: 0 0 3px #ccc;
    min-width: 25vw;
    min-height: 25vw;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
}

.standard-form-container {
    width: 300px;
}

.modal-content > div:only-child {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.modal-title-row {
    position: absolute;
    left: 20px;
    top: 20px;
    font-weight: bold;
    font-size: 25px;
    display: flex;
    align-items: center;
    background: inherit;
    width: calc(100%, -20px);
    z-index: 999;
}

.modal-content-with-title-row {
    margin-top: 40px; /* (modal-title-row top position): 20px + (modal-title-row height): 52px - (modal padding): 32px */
}

.modal-form {
    min-width: 200px;
}

.fieldset-container {
    width: 100%;
}

.form-field {
    float: right;
}

.standard-link {
    color: white;
}

.standard-link:visited {
    color: white;
}

.undecorated-link {
    color: white;
    text-decoration: none;
}

.undecorated-link:disabled {
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
}

.undecorated-link:hover:disabled {
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
}

.undecorated-link:hover:not(:disabled) {
    color: #2a52be !important;
}

.undecorated-link:visited {
    color: white;
    text-decoration: none;
}

.modal-form-submit-btn {
    text-align: center;
}

.tab-list {
    list-style-type: none;
}

.tab-list-tab {
    float: left;
}

.tab-panel {
    border: 1px solid white;
}

.react-tabs {
    -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0 0 10px;
    padding: 0;
}

.react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
}

.react-tabs__tab--selected:after {
    content: '';
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
}

.react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
}

.react-tabs__tab:focus {
    outline: none;
}

.react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
}

.react-tabs__tab-panel {
    display: none;
}

.react-tabs__tab-panel--selected {
    display: block;
}

.progress {
    border-radius: 2px;
    background-color: black;
}

.progress-bar {
    height: 5px;
    background-color: white;
    border-radius: 2px;
}

.file-name {
    font-size: 8pt;
}

.download-link {
    color: white;
}

.standard-link-button-large {
    border: 1px solid white;
    border-radius: 5px;
    color: white;
    text-decoration: none;
    padding: 5px;
    margin: 5px;
    font-size: 16px;
}

.standard-link-button-large:visited {
    color: white;
    text-decoration: none;
}

.standard-link-button-large:hover {
    color: black;
    background-color: white;
    text-decoration: none;
}

.standard-link-button-large:hover:visited {
    color: black;
    background-color: white;
    text-decoration: none;
}

.search-form {
    display: grid;
    grid-template-rows: 2em;
    grid-template-columns: 1fr auto;
}

.search-input {
    background-color: transparent;
    color: white;
    padding: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid white;
    border-right: none;
}

.rw-input {
    background-color: transparent;
    color: white;
    padding: 5px;
    font-family: -apple-system, BlinkMacSystemFont, 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    font-size: 13.3333px;
}

.rw-input:disabled {
    background-color: transparent;
}

.rw-widget-picker {
    min-height: auto;
    background-color: transparent;
    border: none;
    border-radius: 0%;
}

.rw-widget-input {
    color: white;
    background-color: transparent;
    box-shadow: none;
    background-clip: padding-box;
    width: inherit;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid white;
    border-right: none;
    height: 30px;
}

.rw-widget-input:disabled {
    background-color: transparent;
}

.rw-widget-input.rw-state-disabled, .rw-state-disabled .rw-widget-input, fieldset[disabled] .rw-widget-input {
    background-color: transparent;
}

.rw-state-focus .rw-widget-picker {
    border-color: white;
    box-shadow: none;
    transition: box-shadow 0.15s ease-in-out;
    border: 2px solid white;
    border-right: none;
    outline: 0;
}

.rw-state-focus .rw-widget-picker.rw-widget-input {
    box-shadow: none;
    border: 2px solid white;
    border-right: none;
    outline: 0;
}

.rw-popup {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    margin-top: 2px;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border: none;
    background-clip: padding-box;
    background: #121212;
    color: white;
    background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
}

.rw-list-option,
.rw-list-option-create {
    -webkit-user-select: none;
    user-select: none;
    color: white;
    cursor: pointer;
    border: none;
    background-clip: padding-box;
}

.rw-list-option:hover,
.rw-list-option-create:hover,
[data-intent=keyboard] .rw-list-option.rw-state-focus:not(.rw-state-selected),
[data-intent=keyboard] .rw-state-focus.rw-list-option-create:not(.rw-state-selected) {
    background-color: #303030;
    border-color: #303030;
}

.search-input:focus {
    border: 2px solid white;
    outline: 0;
    border-right: none;
}

.search-button {
    background-color: transparent;
    color: white;
    padding: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid white;
    border-left: none;
    height: 30px;
}

.rw-state-focus+.search-button {
    border: 2px solid white;
    border-left: none;
}

.search-input:focus+.search-button {
    border: 2px solid white;
    border-left: none;
}

.search-button:hover {
    color: black;
    background-color: white;
    cursor: pointer;
}

.search-button:hover:disabled {
    color: white;
    background-color: transparent;
    cursor: not-allowed;
}

.tag-selector-div {
    width: 250px;
}

.material-input {
    width: 250px;
    height: 25px;
}

.padded-row>td {
    padding-bottom: 10px;
}

.footnote {
    font-size: 7pt;
}

.material-row {
    margin-top: 10px;
}

.material-row-flex {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
}

.material-row-flex > * {
    flex-grow: 1;
}

.multiline-text {
    white-space: pre-wrap;
}

.form-row-full-td {
    padding-top: 5px;
}

h2 {
    word-wrap: break-word;
}

p {
    word-wrap: break-word;
}

.inline-form-control-label {
    padding-left: 5px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0 30px #161b22 inset;
}

.error-txt {
    color: red;
}

.fieldset-paper {
    padding: 15px;
    margin-bottom: 5px;
    margin-top: 10px;
}

.autocomplete-container {
    flex: 1;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
}

.form-container {
    gap: 10px;
}


.form-container-center {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@media all and (orientation:portrait) {
    .form-paper {
        min-width: max(25vh, min(375px, calc(100vw - 40px)));
        min-height: max(25vh, min(375px, calc(100vh - 60px)));
        margin-bottom: 10px;
    }
}

@media all and (orientation:landscape) {
    .form-paper {
        min-width: max(25vw, min(375px, calc(100vw - 40px)));
        min-height: max(25vw, min(375px, calc(100vh - 60px)));
        margin: 10px;
    }
}

.form-paper {
    display: flex;
    width: max-content;
    height: max-content;
    max-width: calc(100vw - 40px);
    overflow: auto;
    flex-direction: column;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.form-paper-content {
    display: flex;
    flex-direction: column;
    gap: 25px;
    min-width: 325px;
    text-align: center;
}

.button-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.form-paper-button-row {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
}

.form-paper-button--expanded {
    justify-content: space-between;
}

.form-paper-content-top-btn-row {
    position: relative;
}

.form-paper-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.full-loading-container {
    width: 100%;
    height: 100%;
    min-width: 300px;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}
