#EditHistoryDialogue {
    width: 100%;
    min-width: 15vw;
    height: 100%;
    display: flex;
}

.snapshot-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    width: 100%;
}

.snapshot-values-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    width: 100%;
}

.edit-history-snapshots {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.edit-history-pagination {
    display: flex;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    color: white;
}

.snapshot-container-top-row {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    width: 100%;
    align-items: stretch;
    justify-content: space-between;
}

.snapshot-container-update-info {
    display: flex;
    gap: 10px;
    flex-direction: column;
}
